@font-face {
  font-family: 'scubo-icon';
  src:  url('../fonts/scubo-icon.eot?c7sdko');
  src:  url('../fonts/scubo-icon.eot?c7sdko#iefix') format('embedded-opentype'),
    url('../fonts/scubo-icon.ttf?c7sdko') format('truetype'),
    url('../fonts/scubo-icon.woff?c7sdko') format('woff'),
    url('../fonts/scubo-icon.svg?c7sdko#scubo-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="scubo-icon-"], [class*=" scubo-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'scubo-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scubo-icon-magnifying-glass:before {
  content: "\e908";
}
.scubo-icon-aqualung:before {
  content: "\e900";
}
.scubo-icon-checked:before {
  content: "\e901";
}
.scubo-icon-scuba:before {
  content: "\e902";
}
.scubo-icon-scuba-diving:before {
  content: "\e903";
}
.scubo-icon-snorkel:before {
  content: "\e904";
}
.scubo-icon-snorkeling:before {
  content: "\e905";
}
.scubo-icon-swimming:before {
  content: "\e906";
}
.scubo-icon-plus-symbol:before {
  content: "\e907";
}
