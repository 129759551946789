/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# common
# Animations 
# Topbar 
	## Topbar Two 
# Main Navigation 
	## Main Nav Two 
# SideMenu 
# Main Footer 
	## Footer Bottom 
# Slider 
	## Slider Two 
# Page Header 
# Gallery 
# Maps 
# Contact 
# Call To Action 
# Blog 
	## Blog Details 
	## Blog Author 
# Comment 
# Sidebar 
# Course 
	## Course Details
# About 
# Pricing 
# Brand 
# Team 
	## Team Progress
# Testimonials 
# Funfact 
# Video 
# Features 
# Services 
# FAQ 
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# common
--------------------------------------------------------------*/

/* Template Variables */

/* font-family: 'Barlow Condensed', sans-serif;
font-family: 'Ubuntu', sans-serif; */

:root {
  --thm-font: "Barlow Condensed", sans-serif;
  --heading-font: "Ubuntu", sans-serif;
  --thm-base: #3b91e1;
  --thm-base-rgb: 59, 145, 225;
  --thm-primary: #ff010e;
  --thm-black: #051b35;
  --thm-black-rgb: 5, 27, 53;
  --thm-gray: #edf2f5;
}

body {
  font-family: var(--thm-font);
  font-size: 20px;
  line-height: 34px;
  color: #838a93;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.block-title {
  margin-bottom: 45px;
}

.block-title > img {
  display: block;
}

.block-title.text-center > img {
  margin-left: auto;
  margin-right: auto;
}

.block-title p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #838a93;
  letter-spacing: 0.4em;
  line-height: 1em;
  margin-top: 20px;
  margin-bottom: 15px;
}

.block-title h3 {
  margin: 0;
  font-size: 70px;
  font-weight: bold;
  font-family: var(--thm-font);
  color: var(--thm-black);
}

.thm-btn {
  display: inline-block;
  vertical-align: middle;
  border: none;
  outline: none;
  background-color: var(--thm-primary);
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  color: var(--thm-black);
  padding: 25px 61.5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-btn:hover {
  background-color: var(--thm-base);
  color: #fff;
}

/* Cursor*/

.cursor {
  position: absolute;
  background-color: #fff;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: scale(1);
  transform: scale(1);
  visibility: hidden;
}

.cursor {
  visibility: visible;
}

.cursor.active {
  opacity: 0.5;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.cursor.hovered {
  opacity: 0.08;
}

.cursor-follower {
  position: absolute;
  background-color: RGBA(255, 255, 255, 0.3);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  visibility: hidden;
}

.cursor-follower {
  visibility: visible;
}

.cursor-follower.active {
  opacity: 0.7;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.cursor-follower.hovered {
  opacity: 0.08;
}

.cursor-follower.close-cursor:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 2px;
  background: #fff;
  left: 48%;
  top: 12px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: inline-block;
}

.cursor-follower.close-cursor:after {
  position: absolute;
  content: "";
  height: 25px;
  width: 2px;
  background: #fff;
  right: 48%;
  top: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* bootstrap select */
.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show > .btn-light.dropdown-toggle {
  background-color: inherit !important;
  box-shadow: none !important;
  outline: none !important;
}

.bootstrap-select > .dropdown-toggle {
  box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
}

.bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid #f4f4f4;
}

.bootstrap-select .dropdown-menu > li:first-child > a {
  padding-top: 10px;
}

.bootstrap-select .dropdown-menu > li:last-child > a {
  padding-bottom: 10px;
}

.bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--thm-base);
  color: #fff;
}

.bootstrap-select .dropdown-menu > li > a {
  font-size: 16px;
  font-weight: 500;
  padding: 9px 20px;
  color: var(--thm-black);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu > li > a:hover {
  background: var(--thm-base);
  color: #fff;
  cursor: pointer;
}

/* preloader */

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  width: 50px;
  height: 50px;
}

.scroll-to-top {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #292728;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 990;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: none;
  border: 1px solid #393838;
}

@media (max-width: 480px) {
  .scroll-to-top {
    width: 40px;
    height: 40px;
  }
}

.scroll-to-top i {
  font-size: 14px;
  line-height: 50px;
  color: #fff;
  position: relative;
  z-index: 10;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

@media (max-width: 480px) {
  .scroll-to-top i {
    font-size: 14px;
    line-height: 40px;
  }
}

.scroll-to-top:hover {
  background: var(--thm-base);
  border-color: var(--thm-base);
}

.scroll-to-top:hover i {
  color: #fff;
}

.post-pagination {
  text-align: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.post-pagination a {
  color: var(--thm-black);
  font-size: 18px;
  font-weight: 500;
  border-style: solid;
  border-width: 1px;
  border-color: #e3e9ec;
  padding-left: 18.5px;
  padding-right: 18.5px;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.post-pagination a.active,
.post-pagination a:hover {
  color: #fff;
  background-color: var(--thm-primary);
  border-color: var(--thm-primary);
}

.post-pagination a + a {
  margin-left: 10px;
}

.post-pagination a.active {
  cursor: auto;
}

.post-pagination__prev,
.post-pagination__next {
  border: none !important;
  background-color: transparent !important;
  text-decoration: underline;
  color: var(--thm-black) !important;
  text-transform: uppercase;
  font-size: 14px !important;
}

/* datepicker */
.datepicker-dropdown {
  min-width: 300px;
}

.datepicker-dropdown table {
  width: 100%;
}

.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active {
  background-color: var(--thm-base);
  background-image: none;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
  background-color: var(--thm-base);
  background-image: none;
}

/*-------------------------------------------------------------- 
 # Animations 
 --------------------------------------------------------------*/

@-webkit-keyframes bgSlide {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@keyframes bgSlide {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@-webkit-keyframes bgSlideReverse {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: 100px 0;
  }

  40% {
    background-position: 200px 0;
  }

  60% {
    background-position: 150px 0;
  }

  80% {
    background-position: 100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@keyframes bgSlideReverse {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: 100px 0;
  }

  40% {
    background-position: 200px 0;
  }

  60% {
    background-position: 150px 0;
  }

  80% {
    background-position: 100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@-webkit-keyframes ribbonRotate {
  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

@keyframes ribbonRotate {
  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

@-webkit-keyframes footerTree {
  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}

@keyframes footerTree {
  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}

@-webkit-keyframes footerFish {
  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform: translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(20px) translateY(30px) rotate(25deg);
    transform: translateX(20px) translateY(30px) rotate(25deg);
  }

  50% {
    -webkit-transform: translateX(40px) translateY(60px) rotate(45deg);
    transform: translateX(40px) translateY(60px) rotate(45deg);
  }
}

@keyframes footerFish {
  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform: translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(20px) translateY(30px) rotate(25deg);
    transform: translateX(20px) translateY(30px) rotate(25deg);
  }

  50% {
    -webkit-transform: translateX(40px) translateY(60px) rotate(45deg);
    transform: translateX(40px) translateY(60px) rotate(45deg);
  }
}

@-webkit-keyframes contactSwimmer {
  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform: translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(15px) translateY(20px) rotate(10deg);
    transform: translateX(15px) translateY(20px) rotate(10deg);
  }

  50% {
    -webkit-transform: translateX(60px) translateY(35px) rotate(15deg);
    transform: translateX(60px) translateY(35px) rotate(15deg);
  }
}

@keyframes contactSwimmer {
  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform: translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(15px) translateY(20px) rotate(10deg);
    transform: translateX(15px) translateY(20px) rotate(10deg);
  }

  50% {
    -webkit-transform: translateX(60px) translateY(35px) rotate(15deg);
    transform: translateX(60px) translateY(35px) rotate(15deg);
  }
}

/*-------------------------------------------------------------- 
 # Topbar 
 --------------------------------------------------------------*/

.topbar-one {
  background-color: var(--thm-base);
}

.topbar-one .container {
  padding-top: 7px;
  padding-bottom: 7px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.topbar-one__left {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.topbar-one__left a {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.topbar-one__left a + a {
  margin-left: 25px;
}

.topbar-one__left a:hover {
  color: var(--thm-black);
}

.topbar-one__social {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.topbar-one__social a {
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.topbar-one__social a:hover {
  color: var(--thm-black);
}

.topbar-one__social a + a {
  margin-left: 30px;
}

/*-------------------------------------------------------------- 
 ## Topbar Two 
 --------------------------------------------------------------*/

.topbar-two {
  background-color: var(--thm-black);
  margin-bottom: 20px;
}

.topbar-two .topbar-one__left a:hover {
  color: var(--thm-primary);
}

/*-------------------------------------------------------------- 
 # Main Navigation 
 --------------------------------------------------------------*/

.main-nav-one {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

/* main nav infos */

.main-nav-one .container {
  position: relative;
}

.main-nav-one__infos-email {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.main-nav-one__infos-email i {
  margin-left: 10px;
}

.main-nav-one__infos-phone i {
  margin-right: 10px;
}

.main-nav-one__infos-phone {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(100%);
  transform: translateY(-50%) translateX(100%);
  right: 0;
}

.main-nav-one__infos-phone,
.main-nav-one__infos-email {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #838a93;
  font-size: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-nav-one__infos-email:hover {
  color: var(--thm-black);
}

.main-nav-one__infos-phone i,
.main-nav-one__infos-email i {
  width: 36px;
  height: 36px;
  background-color: var(--thm-primary);
  font-size: 12px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-nav-one__infos-phone:hover i,
.main-nav-one__infos-email:hover i {
  background-color: var(--thm-black);
  color: #fff;
}

@media (max-width: 1550px) {
  .main-nav-one__infos-email {
    -webkit-transform: translateY(-50%) translateX(50%);
    transform: translateY(-50%) translateX(50%);
  }

  .main-nav-one__infos-phone {
    -webkit-transform: translateY(-50%) translateX(30%);
    transform: translateY(-50%) translateX(30%);
  }
}

@media (max-width: 1400px) {
  .main-nav-one__infos {
    display: none;
  }
}

.main-nav-one .inner-container {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.main-nav-one .logo-box {
  width: 100%;
  max-width: 281px;
  background-color: #fff;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-59%, calc(-40% + 25px));
  transform: translate(-59%, calc(-40% + 25px));
  text-align: center;
  padding: 36.5px 0;
  border-bottom: 10px solid var(--thm-primary);
  z-index: 10;
}

.main-nav-one .logo-box .side-menu__toggler {
  display: none;
}

.main-nav-one .main-nav__main-navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav-one .main-nav__main-navigation .main-nav__navigation-box {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.main-nav-one .main-nav__main-navigation .main-nav__navigation-box > li {
  padding: 37px 0;
}

.main-nav-one .main-nav__main-navigation .main-nav__navigation-box > li + li {
  margin-left: 64px;
}

.main-nav-one
  .main-nav__main-navigation
  .main-nav__navigation-box
  > li:nth-child(3) {
  margin-right: auto;
}

.main-nav-one
  .main-nav__main-navigation
  .main-nav__navigation-box
  > li:nth-child(4) {
  margin-left: auto;
}

.main-nav-one .main-nav__main-navigation .main-nav__navigation-box > li > a {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  color: #838a93;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-nav-one
  .main-nav__main-navigation
  .main-nav__navigation-box
  > li:hover
  > a,
.main-nav-one
  .main-nav__main-navigation
  .main-nav__navigation-box
  > li.current
  > a {
  color: var(--thm-base);
}

/* Dropdown menu */

.main-nav__main-navigation .dropdown-btn {
  display: none;
}

.main-nav__main-navigation .main-nav__navigation-box > li ul {
  position: absolute;
  width: 210px;
  background-color: #fff;
  border-top: 2px solid var(--thm-primary);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -webkit-transform-origin: top;
  transform-origin: top;
  visibility: hidden;
  opacity: 0;
  z-index: 991;
  -webkit-transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
}

.main-nav__main-navigation .main-nav__navigation-box > li ul > li {
  position: relative;
}

.main-nav__main-navigation .main-nav__navigation-box > li ul > li + li {
  border-top: 1px solid rgba(var(--thm-base-rgb), 0.3);
}

.main-nav__main-navigation .main-nav__navigation-box > li ul > li > a {
  display: block;
  color: var(--thm-black);
  font-size: 16px;
  font-family: var(--thm-font);
  word-break: break-all;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-nav__main-navigation .main-nav__navigation-box > li ul > li:hover > a {
  color: #fff;
  background-color: var(--thm-base);
}

/* Second level menu */

.main-nav__main-navigation .main-nav__navigation-box > li > ul {
  top: 100%;
  left: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);

  visibility: hidden;
  opacity: 0;
}

.main-nav__main-navigation .main-nav__navigation-box > li:hover > ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

/* Third level menu */

.main-nav__main-navigation .main-nav__navigation-box > li > ul > li > ul {
  top: 0;
  left: 100%;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
  visibility: hidden;
  opacity: 0;
}

.main-nav__main-navigation .main-nav__navigation-box > li > ul > li:hover > ul {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  visibility: visible;
  opacity: 1;
}

/* After Third level menu */

.main-nav__main-navigation
  .main-nav__navigation-box
  > li
  > ul
  > li
  > ul
  > li
  > ul {
  display: none;
}

.stricked-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #ffffff;
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.stricked-menu .logo-box {
  padding: 26px 0 20px;
  -webkit-transform: translate(-59%, calc(-55% + 25px));
  transform: translate(-59%, calc(-55% + 25px));
  border-bottom: 5px solid var(--thm-primary);
  background-color: #fff;
}

.stricked-menu.stricky-fixed {
  opacity: 1;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.stricked-menu .main-nav__main-navigation .main-nav__navigation-box > li {
  padding-top: 25px;
  padding-bottom: 25px;
}

/*-------------------------------------------------------------- 
 ## Main Nav Two 
 --------------------------------------------------------------*/

.header-two__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 91;
}

.main-nav-two {
  background-color: transparent;
  box-shadow: none;
}

.main-nav-two .logo-box {
  background-color: var(--thm-base);
  border: 8px solid #fff;
  max-width: 227px;
  padding: 31.5px 0;
  position: relative;
  top: auto;
  left: auto;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}

.main-nav-two .inner-container {
  background-color: #fff;
  max-width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.main-nav-two .main-nav__main-navigation {
  margin-left: auto;
  padding-right: 60px;
}

.main-nav-two .main-nav__main-navigation .main-nav__navigation-box > li + li {
  margin-left: 60px;
}

.main-nav-two
  .main-nav__main-navigation
  .main-nav__navigation-box
  > li:nth-child(4) {
  margin-left: 60px;
}

.main-nav-two .main-nav__main-navigation .main-nav__navigation-box > li {
  padding: 42px 0;
}

.main-nav-two__social {
  width: 100%;
  max-width: 227px;
  height: 57px;
  background-color: var(--thm-primary);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  top: 100%;
  right: 15px;
}

.main-nav-two__social a {
  font-size: 16px;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-nav-two__social a:hover {
  color: var(--thm-base);
}

.main-nav-two__social a + a {
  margin-left: 30px;
}

.main-nav-two .main-nav-one__infos {
  display: block;
}

.main-nav-two .main-nav-one__infos-phone {
  -webkit-transform: translateY(-50%) translateX(calc(100% - 18px));
  transform: translateY(-50%) translateX(calc(100% - 18px));
}

.main-nav-two.stricked-menu {
  background-color: #fff;
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
}

.main-nav-two.stricked-menu .main-nav-two__social {
  display: none;
}

.main-nav-two.stricked-menu .logo-box::before {
  content: "";
  width: 10000px;
  height: 100%;
  background-color: var(--thm-base);
  position: absolute;
  right: 0;
  top: 0;
}

.main-nav-two.stricked-menu .logo-box img {
  position: relative;
}

.main-nav-two.stricked-menu .main-nav__main-navigation {
  padding-right: 0;
}

/*-------------------------------------------------------------- 
 # SideMenu 
 --------------------------------------------------------------*/

.side-menu__block {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: left center;
  transform-origin: right center;
  -webkit-transition: -webkit-transform 0.7s ease;
  transition: -webkit-transform 0.7s ease;
  transition: transform 0.7s ease;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
}

.side-menu__block.active {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.side-menu__block-overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0.7;
}

.side-menu__block-inner {
  position: relative;
  width: 300px;
  margin-left: auto;
  height: 100vh;
  background-color: var(--thm-black);
  z-index: 999999;
  overflow-y: auto;
  padding: 40px 0;
  padding-top: 60px;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-content: space-between;
}

.side-menu__block-inner .mCustomScrollBox {
  width: 100%;
}

.side-menu__close-btn {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 9999999;
  font-size: 18px;
  color: #fff;
  opacity: 1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.side-menu__close-btn:hover {
  color: var(--thm-base);
}

.mobile-nav__container {
  margin: 40px 0;
  border-top: 1px solid rgba(var(--thm-base-rgb), 0.3);
  border-bottom: 1px solid rgba(var(--thm-base-rgb), 0.3);
}

.mobile-nav__container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav__container li.dropdown ul {
  display: none;
}

.mobile-nav__container li.dropdown {
  position: relative;
}

.mobile-nav__container li.dropdown .dropdown-btn {
  border: none;
  outline: none;
  width: 35px;
  height: 35px;
  background-color: #000000;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 10px;
  right: 0;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: transfrom 500ms ease, color 500ms ease,
    background-color 500ms ease;
  transition: transfrom 500ms ease, color 500ms ease,
    background-color 500ms ease;
}

.mobile-nav__container li.dropdown .dropdown-btn.open {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: #ffffff;
  color: var(--thm-black);
}

.mobile-nav__container li + li {
  border-top: 1px solid rgba(var(--thm-base-rgb), 0.3);
}

.mobile-nav__container li > ul {
  border-top: 1px solid rgba(var(--thm-base-rgb), 0.3);
}

.mobile-nav__container li a {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  display: block;
  font-family: var(--thm-font);
  padding: 10px 0;
  padding-left: 30px;
  padding-right: 30px;
  border-left: 3px solid transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
}

.mobile-nav__container li a:hover {
  background-color: var(--thm-base);
  color: #ffffff;
}

.mobile-nav__container li.current-menu-item > a,
.mobile-nav__container li.current > a {
  border-color: var(--thm-primary);
  background-color: var(--thm-base);
}

.mobile-nav__container .navigation-box__right {
  border-top: 1px solid rgba(var(--thm-base-rgb), 0.3);
}

.side-menu__logo {
  padding-left: 30px;
}

.side-menu__block__copy {
  color: #cccccc;
  font-family: var(--thm-font);
  font-size: 18px;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.side-menu__block__copy a {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.side-menu__block__copy a:hover {
  color: var(--thm-base);
}

.side-menu__social {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.side-menu__social a {
  color: #ffffff;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.side-menu__social a:hover {
  color: var(--thm-base);
}

.side-menu__social a + a {
  margin-left: 19px;
}

/*-------------------------------------------------------------- 
 # Main Footer 
 --------------------------------------------------------------*/

.site-footer-one {
  background-color: #063a7a;
  position: relative;
}

.site-footer-one__upper {
  padding-top: 120px;
  padding-bottom: 90px;
}

.site-footer-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #063a7a;
  opacity: 0.05;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: overlay;
}

.site-footer-one .container {
  position: relative;
  z-index: 11;
}

.footer-widget-row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.footer-widget {
  -webkit-box-flex: 1;
  flex: 1 1 25%;
  max-width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}

/* spacings */

.footer-widget__about {
  -webkit-box-flex: 1;
  flex: 1 1 16.666%;
  max-width: 16.666%;
}

.footer-widget__links__widget-1,
.footer-widget__links__widget-2,
.footer-widget__links__widget-3 {
  -webkit-box-flex: 1;
  flex: 1 1 19.4446%;
  max-width: 19.4446%;
}

@media (min-width: 992px) {
  .footer-widget__links__widget-1 .footer-widget__inner {
    padding-left: 55px;
  }

  .footer-widget__links__widget-2 .footer-widget__inner {
    padding-left: 20px;
  }

  .footer-widget__links__widget-3 .footer-widget__inner {
    padding-left: 30px;
  }

  .footer-widget__social__widget {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
  }
}

.footer-widget__title {
  font-family: var(--thm-font);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 40px;
  color: #fff;
  text-transform: uppercase;
}

.footer-widget p {
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  color: #c1cfe1;
}

.footer-widget__about p {
  margin-top: 30px;
}

.footer-widget__links-list {
  margin: 0;
  margin-top: -10px;
}

.footer-widget__links-list li {
  line-height: 1em;
}

.footer-widget__links-list li + li {
  margin-top: 18px;
}

.footer-widget__links-list li a {
  margin: 0;
  color: #c1cfe1;
  font-size: 18px;
  font-weight: 400;
  padding-left: 0;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__links-list li a::before {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  -webkit-transform: translateY(-50%) translateX(-10px);
  transform: translateY(-50%) translateX(-10px);
  left: 0;
  top: 60%;
  color: var(--thm-primary);
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__links-list li a:hover {
  color: #fff;
  padding-left: 20px;
}

.footer-widget__links-list li a:hover::before {
  opacity: 1;
  -webkit-transform: translateY(-50%) translateX(0px);
  transform: translateY(-50%) translateX(0px);
}

.footer-widget__social {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.footer-widget__social a {
  width: 41px;
  height: 41px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: var(--thm-black);
  background-color: var(--thm-primary);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-widget__social a:hover {
  color: #fff;
  background-color: var(--thm-black);
}

.footer-widget__social a + a {
  margin-left: 10px;
}

/* footer shapes */

.site-footer__tree-1 {
  position: absolute;
  bottom: 0;
  left: 5%;
  z-index: 10;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-animation: footerTree 5s ease-in infinite;
  animation: footerTree 5s ease-in infinite;
}

.site-footer__tree-2 {
  position: absolute;
  bottom: 0;
  right: 10%;
  z-index: 10;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-animation: footerTree 5s ease-in infinite;
  animation: footerTree 5s ease-in infinite;
}

.site-footer__fish-1 {
  position: absolute;
  left: 7%;
  bottom: 49%;
  z-index: 10;
  -webkit-perspective: 100px;
  perspective: 100px;
  -webkit-animation: footerFish 5s linear infinite;
  animation: footerFish 5s linear infinite;
}

.site-footer__fish-2 {
  position: absolute;
  z-index: 10;
  left: 45%;
  top: 15%;
  -webkit-perspective: 150px;
  perspective: 150px;
  -webkit-animation: footerFish 5s linear infinite 1s;
  animation: footerFish 5s linear infinite 1s;
}

.site-footer__fish-3 {
  position: absolute;
  top: 20%;
  right: 12%;
  z-index: 11;
  -webkit-perspective: 250px;
  perspective: 250px;
  -webkit-animation: footerFish 5s linear infinite 2s;
  animation: footerFish 5s linear infinite 2s;
}

/*-------------------------------------------------------------- 
 ## Footer Bottom 
 --------------------------------------------------------------*/

.site-footer__bottom a {
  display: block;
  background-color: #023069;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  padding-top: 41px;
  padding-bottom: 41px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__bottom a:hover {
  background-color: var(--thm-primary);
  color: var(--thm-black);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.site-footer__bottom a i {
  width: 36px;
  height: 36px;
  background-color: var(--thm-primary);
  color: var(--thm-black);
  font-size: 14px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.site-footer__bottom a:hover i {
  color: #fff;
  background-color: var(--thm-black);
}

/*-------------------------------------------------------------- 
 # Slider 
 --------------------------------------------------------------*/

[class*="slider-one__slide-"] {
  padding-top: 209px;
  padding-bottom: 209px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-one__content .anim-elm {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.slider-one__content p {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  opacity: 0.6;
  letter-spacing: 0.4em;
}

.slider-one__content h3 {
  margin: 0;
  font-size: 120px;
  line-height: 100px;
  color: #fff;
  text-transform: uppercase;
  font-family: var(--thm-font);
  font-weight: 800;
  margin-bottom: 60px;
  margin-top: 15px;
}

/* animating on active */

.slider-one__carousel .active .slider-one__content p {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.slider-one__carousel .active .slider-one__content h3 {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.slider-one__carousel .active .slider-one__content .thm-btn {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

/* slider one dots */

.slider-one__carousel .owl-dots {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 5%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.slider-one__carousel .owl-dots .owl-dot {
  border: none;
  outline: none;
}

.slider-one__carousel .owl-dots .owl-dot + .owl-dot {
  margin-top: 10px;
}

.slider-one__carousel .owl-dots .owl-dot span {
  margin: 0;
  padding: 0;
  background-color: transparent;
  width: 20px;
  height: 20px;
  border-radius: 0;
  border: 5px solid #fff;
  opacity: 0.2;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.slider-one__carousel .owl-dots .owl-dot.active span {
  background-color: transparent;
  opacity: 1;
}

/* slider one nav */

.slider-one {
  position: relative;
}

.slider-one__nav {
  position: absolute;
  top: 50%;
  right: 5%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10;
}

[class*="slider-one__nav-"] {
  width: 79px;
  height: 79px;
  border: 8px solid #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  opacity: 0.2;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

[class*="slider-one__nav-"]:hover {
  color: #fff;
  opacity: 1;
}

[class*="slider-one__nav-"] + [class*="slider-one__nav-"] {
  margin-left: -39.5px;
  margin-top: 10px;
}

/*-------------------------------------------------------------- 
 ## Slider Two 
 --------------------------------------------------------------*/

.slider-two {
  margin-top: 48px;
}

.slider-two [class*="slider-one__slide-"] {
  padding-top: 290px;
  padding-bottom: 183px;
}

.slider-two [class*="slider-one__slide-"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/shapes/slider-top-shadow.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  z-index: 10;
  opacity: 0.5;
}

.slider-two .slider-one__content {
  position: relative;
  z-index: 11;
}

.slider-two .slider-one__content h3 {
  font-size: 110px;
  line-height: 90px;
  margin-top: 10px;
  margin-bottom: 40px;
}

@media (max-width: 1440px) {
  .slider-two .slider-one__carousel .owl-dots {
    left: 1%;
  }
}

/*-------------------------------------------------------------- 
 # Page Header 
 --------------------------------------------------------------*/

.page-header {
  background-color: #063a7a;
  position: relative;
  padding-top: 110px;
}

.page-header__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #063a7a;
  opacity: 0.05;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: overlay;
}

.page-header .container {
  position: relative;
  text-align: center;
  padding-bottom: 70px;
}

.page-header__title {
  margin: 0;
  font-family: var(--thm-font);
  font-weight: 800;
  color: #fff;
  font-size: 60px;
  text-transform: uppercase;
}

.thm-breadcrumb {
  margin: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.thm-breadcrumb li {
  text-transform: uppercase;
  color: var(--thm-primary);
  font-size: 18px;
  font-weight: 600;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.thm-breadcrumb li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-breadcrumb li.active a,
.thm-breadcrumb li a:hover {
  color: #fff;
}

.thm-breadcrumb li + li::before {
  content: ".";
  align-self: flex-end;
  margin-left: 12px;
  margin-right: 12px;
}

/*-------------------------------------------------------------- 
 # Gallery 
 --------------------------------------------------------------*/

.gallery-one {
  padding-top: 120px;
  padding-bottom: 90px;
}

.gallery-one__single {
  background-color: var(--thm-black);
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.gallery-one__single > img {
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.gallery-one__single:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.gallery-one__single > a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-transform: translateZ(-100px);
  transform: translateZ(-100px);
  opacity: 0;
  border: 8px solid var(--thm-primary);
  background-color: rgba(var(--thm-black-rgb), 0.8);
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease,
    -webkit-transform 500ms ease;
}

.gallery-one__single:hover > a {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.gallery-one__single > a > i {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  width: 91px;
  height: 91px;
  background-color: var(--thm-primary);
  color: var(--thm-black);
  font-size: 22px;
  position: relative;
}

.gallery-one__single > a > i:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.1;
  top: -10px;
  left: -10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.gallery-one__single > a > i:hover:after {
  top: 0px;
  left: 0;
}

/*-------------------------------------------------------------- 
 # Maps 
 --------------------------------------------------------------*/

.google-map__contact {
  display: block;
  border: none;
  width: 100%;
  height: 540px;
}

/*-------------------------------------------------------------- 
 # Contact 
 --------------------------------------------------------------*/

.contact-one {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}

.contact-one__fish {
  position: absolute;
  bottom: 45%;
  left: 10%;
  -webkit-animation: footerFish 5s linear infinite;
  animation: footerFish 5s linear infinite;
}

.contact-one__tree {
  position: absolute;
  bottom: 0;
  right: 4%;
  -webkit-animation: footerTree 5s ease-in infinite;
  animation: footerTree 5s ease-in infinite;
}

.contact-one__swimmer {
  position: absolute;
  bottom: 0;
  left: -5%;
  -webkit-animation: contactSwimmer 7s linear infinite;
  animation: contactSwimmer 7s linear infinite;
}

.contact-one .container {
  position: relative;
}

.contact-one__content h3 {
  text-transform: uppercase;
  margin: 0;
  color: var(--thm-black);
  font-size: 60px;
  font-weight: bold;
  font-family: var(--thm-font);
  margin-bottom: 35px;
}

.contact-one__content p {
  margin: 0;
  color: #838a93;
}

.contact-one__social {
  margin-top: 50px;
}

.contact-one__form textarea,
.contact-one__form
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
  display: block;
  width: 100%;
  height: 84px;
  background-color: var(--thm-gray);
  padding-left: 30px;
  font-size: 18px;
  color: #838a93;
  border: none;
  outline: none;
  margin-bottom: 30px;
}

.contact-one__form textarea {
  height: 203px;
  padding-top: 25px;
}

.contact-one__form textarea.error,
.contact-one__form
  input.error:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
  color: red;
}

.contact-one__form ::-webkit-input-placeholder {
  opacity: 1;
  color: #838a93;
}

.contact-one__form ::-moz-placeholder {
  opacity: 1;
  color: #838a93;
}

.contact-one__form :-ms-input-placeholder {
  opacity: 1;
  color: #838a93;
}

.contact-one__form ::-ms-input-placeholder {
  opacity: 1;
  color: #838a93;
}

.contact-one__form ::placeholder {
  opacity: 1;
  color: #838a93;
}

.contact-one__form .error::-webkit-input-placeholder {
  opacity: 1;
  color: red;
}

.contact-one__form .error::-moz-placeholder {
  opacity: 1;
  color: red;
}

.contact-one__form .error:-ms-input-placeholder {
  opacity: 1;
  color: red;
}

.contact-one__form .error::-ms-input-placeholder {
  opacity: 1;
  color: red;
}

.contact-one__form .error::placeholder {
  opacity: 1;
  color: red;
}

.contact-one__form
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

.contact-one__form .bootstrap-select > .dropdown-toggle {
  width: 100%;
  border-radius: 0;
  background-color: var(--thm-gray);
  height: 84px;
  color: #838a93;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  margin-bottom: 30px;
  border: none;
  outline: none;
}

.contact-one__form .bootstrap-select > .dropdown-toggle .filter-option {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.contact-one__form label.error {
  color: red;
  margin-top: -15px;
  font-size: 18px;
  display: block;
}

.contact-one__form-home-two textarea,
.contact-one__form-home-two
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
  margin-bottom: 10px;
}

.contact-one__form-home-two .row {
  margin-left: -5px;
  margin-right: -5px;
}

.contact-one__form-home-two .row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.contact-one__form-home-two .bootstrap-select > .dropdown-toggle {
  margin-bottom: 10px;
}

.faq-contact__wrap {
  padding-bottom: 120px;
  position: relative;
}

.faq-contact__wrap .inner-container {
  background-color: var(--thm-base);
  position: relative;
  padding-top: 80px;
  padding-bottom: 97px;
  margin-bottom: 60px;
}

.faq-contact__wrap .inner-container .block-title {
  margin-bottom: 0;
}

.faq-contact__wrap .inner-container .block-title h3,
.faq-contact__wrap .inner-container .block-title p {
  color: #fff;
}

.faq-contact__wrap .inner-container::before {
  content: "";
  width: 100%;
  height: 32px;
  display: block;
  background-image: url(../images/shapes/wave-1.png);
  background-position: center center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  -webkit-animation: bgSlide 20s linear infinite;
  animation: bgSlide 20s linear infinite;
}

/*-------------------------------------------------------------- 
 # Call To Action 
 --------------------------------------------------------------*/

.cta-one .container {
  position: relative;
}

.cta-one__title {
  text-transform: uppercase;
  width: 243px;
  height: 96px;
  background-color: var(--thm-black);
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.4em;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 11;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: rotate(-90deg) translateX(calc(-100% + 96px));
  transform: rotate(-90deg) translateX(calc(-100% + 96px));
}

@media (max-width: 1280px) {
  .cta-one__title {
    left: 111px;
  }
}

.cta-one .inner-container {
  padding: 69.5px 0;
  background-color: var(--thm-primary);
  position: relative;
  z-index: 10;
  margin-bottom: -139px;
}

@media (max-width: 1280px) {
  .cta-one .inner-container {
    padding-left: 111px;
  }
}

.cta-one .inner-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 10px;
  height: 35px;
  background-color: var(--thm-black);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  z-index: 11;
}

@media (max-width: 1280px) {
  .cta-one .inner-container::before {
    left: 95px;
  }
}

.cta-one .inner-container::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 10000px;
  background-color: var(--thm-primary);
}

.cta-one .my-auto {
  width: 100%;
}

.cta-one h3 {
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--thm-font);
  font-size: 40px;
  color: var(--thm-black);
  padding-left: 80px;
}

.cta-one__phone {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: var(--thm-black);
  font-size: 40px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
  margin-left: 100px;
}

.cta-one__phone::before {
  content: "";
  width: 9px;
  height: 57px;
  background-image: url(../images/shapes/line-2.png);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cta-one__phone a {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: inherit;
}

.cta-one__phone:hover a,
.cta-one__phone:hover {
  color: var(--thm-base);
}

.cta-one__phone i {
  width: 76px;
  height: 76px;
  background-color: var(--thm-black);
  font-size: 24px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: #fff;
  margin-right: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.cta-one__phone:hover i {
  background-color: #fff;
  color: var(--thm-black);
}

.cta-two {
  background-color: #063a7a;
  position: relative;
  margin-top: -1px;
}

.cta-two::before {
  content: "";
  width: 100%;
  height: 32px;
  display: block;
  background-image: url(../images/shapes/wave-1.png);
  background-position: center center;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  -webkit-animation: bgSlide 20s linear infinite;
  animation: bgSlide 20s linear infinite;
}

.cta-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-color: #063a7a;
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.05;
}

.cta-two__moc {
  position: absolute;
  top: -84px;
  left: 0;
  z-index: 12;
  -webkit-animation: ribbonRotate 10s cubic-bezier(0.19, 1, 1, 3.58) infinite;
  animation: ribbonRotate 10s cubic-bezier(0.19, 1, 1, 3.58) infinite;
}

.cta-two .container {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 130px;
  padding-bottom: 130px;
}

.cta-two h3 {
  margin: 0;
  font-family: var(--thm-font);
  font-weight: bold;
  color: #fff;
  font-size: 36px;
}

.cta-two h3 span {
  color: var(--thm-primary);
}

.cta-two__home-two {
  background-color: var(--thm-base);
}

.cta-two__home-two::before {
  background-image: url(../images/shapes/wave-3.png);
  top: -7px;
  left: 0;
  bottom: auto;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cta-three,
.cta-three .container,
.cta-three__content {
  position: relative;
}

.cta-three::after {
  content: "";
  width: 100%;
  height: 50%;
  background-color: var(--thm-gray);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.cta-three__content::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 10px;
  height: 35px;
  background-color: var(--thm-primary);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  z-index: 11;
}

.cta-three__moc {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

@media (max-width: 1440px) {
  .cta-three__moc {
    right: -20%;
  }
}

.cta-three .container::before {
  content: "";
  width: 10000px;
  height: 100%;
  background-color: var(--thm-base);
  position: absolute;
  top: 0;
  left: 15px;
}

.cta-three__title {
  text-transform: uppercase;
  width: 570px;
  height: 96px;
  background-color: var(--thm-primary);
  color: var(--thm-black);
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.4em;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 11;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: rotate(-90deg) translateX(calc(-100% + 96px));
  transform: rotate(-90deg) translateX(calc(-100% + 96px));
}

@media (max-width: 1280px) {
  .cta-three__title {
    left: 111px;
  }
}

.cta-three__content::before {
  content: "";
  width: 10000px;
  height: 100%;
  background-color: var(--thm-base);
  background-image: url(../images/background/footer-bg-1-1.jpg);
  background-blend-mode: multiply;
  opacity: 0.15;
  position: absolute;
  top: 0;
  left: 0;
}

.cta-three__content-inner {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 100px;
}

.cta-three .block-title {
  margin-bottom: 35px;
}

.cta-three .block-title p {
  color: #fff;
}

.cta-three .block-title h3 {
  color: #fff;
}

.cta-three__content-inner > p {
  margin: 0;
  color: #fff;
  opacity: 0.7;
}

.cta-three__btn {
  margin-top: 50px;
}

.cta-three__btn:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.cta-three__feature {
  background-color: var(--thm-gray);
  padding-top: 120px;
  padding-bottom: 90px;
}

.cta-three__feature .row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.cta-three__feature .row > [class*="col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.cta-three__feature-box {
  padding-top: 43px;
  padding-bottom: 43px;
  padding-left: 45px;
  padding-right: 45px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.cta-three__feature-box:hover {
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.cta-three__feature-box-icon {
  width: 63px;
  height: 63px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.cta-three__feature-box-icon::before {
  content: "";
  position: absolute;
  background-color: var(--thm-primary);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.cta-three__feature-box-icon::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: var(--thm-gray);
  width: 100%;
  height: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.cta-three__feature-box:hover .cta-three__feature-box-icon::after {
  top: 0;
  left: 0;
}

.cta-three__feature-box-icon i {
  font-size: 22px;
  text-align: center;
  color: var(--thm-black);
  position: relative;
  z-index: 11;
}

.cta-three__feature-box h3 {
  color: #063a7a;
  font-family: var(--thm-font);
  text-transform: uppercase;
  margin: 0;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-left: 20px;
}

.cta-four {
  padding-top: 120px;
  padding-bottom: 120px;
}

.cta-four__image {
  position: relative;
}

@media (min-width: 992px) {
  .cta-four__image {
    margin-right: 70px;
  }
}

.cta-four__image > img {
  width: 100%;
  -webkit-clip-path: polygon(50% 0%, 85% 0, 100% 15%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(50% 0%, 85% 0, 100% 15%, 100% 100%, 0 100%, 0 0);
}

.cta-four__image-content {
  width: 100%;
  max-width: 300px;
  height: 100%;
  max-height: 415px;
  background-color: var(--thm-primary);
  position: absolute;
  top: calc(50% - 207.5px);
  left: calc(50% - 150px);
  -webkit-clip-path: polygon(50% 0%, 80% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(50% 0%, 80% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 65px;
}

.cta-four__image-content::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  background-color: #fff;
  -webkit-clip-path: polygon(50% 0%, 80% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(50% 0%, 80% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
}

.cta-four__image-content i {
  position: relative;
  font-size: 94px;
  color: var(--thm-base);
  display: block;
}

.cta-four__image-content p {
  position: relative;
  margin: 0;
  color: var(--thm-primary);
  font-size: 50px;
  font-weight: bold;
  line-height: 1em;
  margin-top: 10px;
}

.cta-four__image-content h3 {
  margin: 0;
  position: relative;
  font-weight: 400;
  color: var(--thm-black);
  font-size: 40px;
  text-transform: uppercase;
  font-family: var(--thm-font);
  margin-top: 5px;
}

.cta-four__content .block-title {
  margin-bottom: 30px;
}

.cta-four__content > p {
  margin: 0;
}

.cta-four__btn {
  margin-top: 50px;
}

.cta-five {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #063a7a;
}

.cta-five__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  opacity: 0.2;
}

.cta-five .container {
  position: relative;
}

.cta-five__content .block-title {
  margin-bottom: 35px;
}

.cta-five__content .block-title p {
  color: #c1cfe1;
}

.cta-five__content .block-title h3 {
  color: #ffffff;
}

.cta-five__content > p {
  margin: 0;
  color: #c1cfe1;
}

.cta-five__btn {
  margin-top: 50px;
}

.cta-five__btn:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.cta-five__box-wrap {
  position: absolute;
  bottom: 20px;
  right: -110px;
  width: 100%;
  max-width: 690px;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1440px) {
  .cta-five__box-wrap {
    right: -55px;
  }
}

.cta-five__box {
  -webkit-box-flex: 1;
  flex: 1 1 33.333%;
  max-width: 33.333%;
  background-color: #fff;
  text-align: center;
  padding-top: 51px;
  padding-bottom: 51px;
}

.cta-five__box:nth-child(2) {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  background-color: var(--thm-base);
}

.cta-five__box:nth-child(2) i,
.cta-five__box:nth-child(2) h3 {
  color: #fff;
}

.cta-five__box:nth-child(3) {
  background-color: var(--thm-primary);
}

.cta-five__box:nth-child(3) i,
.cta-five__box:nth-child(3) h3 {
  color: var(--thm-black);
}

.cta-five__box i {
  font-size: 60px;
  color: var(--thm-base);
}

.cta-five__box h3 {
  color: #063a7a;
  font-family: var(--thm-font);
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0;
  margin-top: 15px;
}

.cta-five__box h3 a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

/*-------------------------------------------------------------- 
 # Blog 
 --------------------------------------------------------------*/

.blog-one {
  padding-top: 120px;
  padding-bottom: 120px;
}

.blog-one__single {
  margin-bottom: 30px;
  padding: 25px;
  padding-bottom: 30px;
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #e3e9ec;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-one__single:hover {
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
}

.blog-one__single::before,
.blog-one__single::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  top: 0;
  left: 0;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}

.blog-one__single::before {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  border-top: 8px solid var(--thm-primary);
  border-bottom: 8px solid var(--thm-primary);
}

.blog-one__single::after {
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  border-left: 8px solid var(--thm-primary);
  border-right: 8px solid var(--thm-primary);
}

.blog-one__single:hover::before,
.blog-one__single:hover::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.blog-one__date {
  background-color: var(--thm-primary);
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
  text-align: center;
  position: absolute;
  top: 10px;
  left: -10px;
  z-index: 11;
  text-transform: uppercase;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-one__date:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.blog-one__date:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0px;
  width: 10px;
  height: 10px;
  background-color: var(--thm-primary);
  -webkit-clip-path: polygon(0 0, 100% 100%, 100% 0);
  clip-path: polygon(0 0, 100% 100%, 100% 0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-one__date:hover:before {
  background-color: var(--thm-base);
}

.blog-one__image {
  position: relative;
}

.blog-one__image-inner {
  position: relative;
  overflow: hidden;
  background-color: var(--thm-black);
  z-index: 10;
}

.blog-one__image-inner > img {
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-one__single:hover .blog-one__image-inner > img {
  opacity: 0.2;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.blog-one__image-inner > a {
  width: 65px;
  height: 65px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  color: var(--thm-black);
  background-color: var(--thm-primary);
  position: absolute;
  top: calc(50% - 32.5px);
  left: calc(50% - 32.5px);
  opacity: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: perspective(500px) rotate(45deg);
  transform: perspective(500px) rotate(45deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: opacity 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, opacity 500ms ease 0ms;
  transition: transform 500ms ease 0ms, opacity 500ms ease 0ms,
    -webkit-transform 500ms ease 0ms;
}

.blog-one__single:hover .blog-one__image-inner > a {
  opacity: 1;
  -webkit-transform: perspective(500px) rotate(0deg);
  transform: perspective(500px) rotate(0deg);
  -webkit-transition: opacity 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, opacity 500ms ease 500ms;
  transition: transform 500ms ease 500ms, opacity 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
}

.blog-one__image-inner > a::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -10px;
  top: -10px;
  background-color: #fff;
  opacity: 0.1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-one__image-inner > a:hover::after {
  left: 0;
  top: 0;
}

.blog-one__content {
  text-align: center;
  padding-top: 31px;
  position: relative;
  z-index: 11;
}

.blog-one__content h3 {
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 34px;
  font-family: var(--thm-font);
  font-weight: bold;
  color: #063a7a;
  width: 68%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
}

.blog-one__content h3 a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-one__content h3 a:hover {
  color: var(--thm-primary);
}

.blog-one__content p {
  margin: 0;
  width: 73%;
  margin-left: auto;
  margin-right: auto;
}

.blog-one__meta {
  border-top: 1px solid #e3e9ec;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  padding-top: 20px;
  margin-top: 35px;
}

.blog-one__meta a {
  font-size: 14px;
  color: var(--thm-base);
  text-transform: uppercase;
  font-weight: 500;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-one__meta a:hover {
  color: var(--thm-black);
}

.blog-one__meta a i {
  margin-right: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-one__meta a:hover i {
  color: var(--thm-primary);
}

.blog-one__meta a + a {
  margin-left: 40px;
}

/* blog post pagination */

.blog-one .post-pagination {
  margin-top: 30px;
}

/* blog home */

.blog-one__home-one {
  padding-bottom: 90px;
  background-position: bottom center;
  background-repeat: no-repeat;
}

/*-------------------------------------------------------------- 
 ## Blog Details 
 --------------------------------------------------------------*/

.blog-details {
  padding-top: 120px;
  padding-bottom: 120px;
}

.blog-details__main-title {
  margin: 0;
  font-family: var(--thm-font);
  font-size: 34px;
  font-weight: bold;
  color: var(--thm-black);
  text-transform: uppercase;
  margin-bottom: 35px;
}

.blog-details__content .blog-one__meta {
  -webkit-box-pack: start;
  justify-content: flex-start;
  border-top: 0;
  padding-top: 0;
  margin-top: 20px;
}

.blog-details__content p {
  margin: 0;
}

.blog-details__content p + p {
  margin-top: 30px;
}

.blog-details__content-bottom {
  border-top: 1px solid #e3e9ec;
  padding-top: 40px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 55px;
}

.blog-details__tags {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.blog-details__tags span {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: var(--thm-black);
  margin-right: 5px;
}

.blog-details__tags a {
  color: #838a93;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-details__tags a:hover {
  color: var(--thm-primary);
}

.blog-details__tags a + a {
  margin-left: 5px;
}

/*-------------------------------------------------------------- 
 ## Blog Author 
 --------------------------------------------------------------*/

.blog-details__author {
  background-color: var(--thm-gray);
  position: relative;
  padding: 60px;
  padding-left: 275px;
  padding-bottom: 70px;
  margin-bottom: 50px;
  margin-top: 60px;
}

.blog-details__author > img {
  position: absolute;
  top: 60px;
  left: 60px;
}

.blog-details__author h3 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 22px;
  font-family: var(--thm-font);
  color: var(--thm-black);
  margin-bottom: 30px;
}

.blog-details__author p {
  margin: 0;
  color: #838a93;
}

/*-------------------------------------------------------------- 
 # Comment 
 --------------------------------------------------------------*/

.comment-one__title,
.comment-form__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--thm-font);
}

.comment-one__title {
  margin-bottom: 45px;
}

.comment-form__title {
  margin-bottom: 50px;
}

.comment-one__single {
  border-bottom: 1px solid #e3e9ec;
  position: relative;
  padding-left: 145px;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.comment-one__single > img {
  position: absolute;
  top: 8px;
  left: 0;
}

.comment-one__top {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
  margin-bottom: 30px;
}

.comment-one__top h3 {
  margin: 0;
  color: var(--thm-black);
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--thm-font);
}

.comment-one__top span {
  color: var(--thm-base);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}

.comment-one__single p {
  margin: 0;
  margin-bottom: 30px;
}

.comment-one__reply {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.comment-one__reply:hover {
  color: var(--thm-primary);
}

/*-------------------------------------------------------------- 
 # Sidebar 
 --------------------------------------------------------------*/

@media (max-width: 991px) {
  .sidebar {
    margin-top: 80px;
  }
}

.sidebar__single:not(.sidear__search) {
  border: 1px solid #e3e9ec;
  padding: 50px;
}

.sidebar__single + .sidebar__single {
  margin-top: 30px;
}

.sidebar__title {
  margin: 0;
  margin-bottom: 35px;
  font-family: var(--thm-font);
  color: var(--thm-black);
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
  margin-top: -5px;
  line-height: 1em;
}

.sidebar__post-list,
.sidebar__category-list {
  margin: 0;
}

.sidebar__post-list {
  margin-top: -10px;
}

.sidebar__post-list li {
  position: relative;
  padding-left: 80px;
}

.sidebar__post-list li > img {
  position: absolute;
  top: 10px;
  left: 0;
}

.sidebar__post-list li + li > img {
  margin-top: 30px;
}

.sidebar__post__meta {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.sidebar__post__meta a {
  font-size: 14px;
  color: var(--thm-base);
  text-transform: uppercase;
  font-weight: 500;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__post__meta a:hover {
  color: var(--thm-black);
}

.sidebar__post__meta a i {
  margin-right: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__post__meta a:hover i {
  color: var(--thm-primary);
}

.sidebar__post__meta a + a {
  margin-left: 15px;
}

.sidebar__post-list li + li {
  border-top: 1px solid #e3e9ec;
  padding-top: 30px;
  margin-top: 25px;
}

.sidebar__post-list li h3 {
  margin: 0;
  color: var(--thm-black);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--thm-font);
}

.sidebar__post-list li h3 a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__post-list li h3 a:hover {
  color: var(--thm-primary);
}

.sidebar__tags-list {
  margin-top: -10px;
}

.sidebar__tags-list a {
  color: #828690;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__tags-list a:hover {
  color: var(--thm-base);
}

.sidebar__category-list {
  margin: 0;
}

.sidebar__category-list li {
  line-height: 1em;
}

.sidebar__category-list li + li {
  margin-top: 35px;
}

.sidebar__category-list li a {
  margin: 0;
  color: #838a93;
  font-size: 20px;
  font-weight: 400;
  padding-left: 0;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__category-list li a::before {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  -webkit-transform: translateY(-50%) translateX(-10px);
  transform: translateY(-50%) translateX(-10px);
  left: 0;
  top: 60%;
  color: var(--thm-primary);
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sidebar__category-list li a:hover {
  color: var(--thm-base);
  padding-left: 20px;
}

.sidebar__category-list li a:hover::before {
  opacity: 1;
  -webkit-transform: translateY(-50%) translateX(0px);
  transform: translateY(-50%) translateX(0px);
}

.sidebar__search-form {
  background-color: var(--thm-base);
  position: relative;
}

.sidebar__search-form
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
  width: 100%;
  height: 94px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  padding-left: 50px;
  font-size: 20px;
}

.sidebar__search-form ::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.sidebar__search-form ::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.sidebar__search-form ::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.sidebar__search-form ::placeholder {
  color: #fff;
  opacity: 1;
}

.sidebar__search-form button[type="submit"] {
  margin: 0;
  padding: 0;
  font-size: 22px;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: none;
  width: auto;
  position: absolute;
  top: 50%;
  right: 50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*-------------------------------------------------------------- 
 # Course 
 --------------------------------------------------------------*/

.course-one {
  padding-top: 120px;
  padding-bottom: 120px;
  counter-reset: courseNumber;
}

.course-one__single {
  position: relative;
  margin-bottom: 30px;
  z-index: 10;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  counter-increment: courseNumber;
}

.course-one__single:hover {
  -webkit-transform: translateY(-76px);
  transform: translateY(-76px);
}

.course-one__single:hover .hvr-sweep-to-bottom::before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.course-one__cat {
  background-color: var(--thm-primary);
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-weight: 600;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 26.5px;
  padding-right: 26.5px;
  text-align: center;
  position: absolute;
  top: 10px;
  left: -10px;
  z-index: 11;
  text-transform: uppercase;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.course-one__cat:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.course-one__cat:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0px;
  width: 10px;
  height: 10px;
  background-color: var(--thm-primary);
  -webkit-clip-path: polygon(0 0, 100% 100%, 100% 0);
  clip-path: polygon(0 0, 100% 100%, 100% 0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.course-one__cat:hover:before {
  background-color: var(--thm-base);
}

.course-one__image {
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.course-one__single:hover .course-one__image {
  -webkit-transform: translateY(76px);
  transform: translateY(76px);
}

.course-one__image-inner {
  position: relative;
  overflow: hidden;
  background-color: var(--thm-black);
  z-index: 10;
}

.course-one__image-inner > img {
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.course-one__single:hover .course-one__image-inner > img {
  opacity: 0.2;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.course-one__image-inner > a {
  width: 65px;
  height: 65px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  color: var(--thm-black);
  background-color: var(--thm-primary);
  position: absolute;
  top: calc(50% - 70.5px);
  left: calc(50% - 32.5px);
  opacity: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: perspective(500px) rotate(45deg);
  transform: perspective(500px) rotate(45deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: opacity 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, opacity 500ms ease 0ms;
  transition: transform 500ms ease 0ms, opacity 500ms ease 0ms,
    -webkit-transform 500ms ease 0ms;
}

.course-one__single:hover .course-one__image-inner > a {
  opacity: 1;
  -webkit-transform: perspective(500px) rotate(0deg);
  transform: perspective(500px) rotate(0deg);
  -webkit-transition: opacity 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, opacity 500ms ease 500ms;
  transition: transform 500ms ease 500ms, opacity 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
}

.course-one__image-inner > a::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -10px;
  top: -10px;
  background-color: #fff;
  opacity: 0.1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.course-one__image-inner > a:hover::after {
  left: 0;
  top: 0;
}

.course-one__content {
  text-align: center;
  background-color: #edf2f5;
  padding-top: 53px;
  padding-bottom: 55px;
}

.course-one__content::after {
  font-family: var(--thm-font);
  font-size: 200px;
  line-height: 1em;
  color: #051b35;
  opacity: 0.06;
  content: counters(courseNumber, ".", decimal-leading-zero);
  position: absolute;
  bottom: -20px;
  left: -10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.course-one__single:hover .course-one__content::after {
  color: #fff;
  opacity: 0.06;
}

.course-one__content h3 {
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  color: #063a7a;
  font-family: var(--thm-font);
  margin-bottom: 24px;
}

.course-one__content h3 a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.course-one__content h3 a:hover {
  color: #fff;
}

.course-one__content p {
  margin: 0;
  width: 67%;
  color: #838a93;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.course-one__single:hover .course-one__content h3 a,
.course-one__single:hover .course-one__content p {
  color: #fff;
}

.course-one__single .course-one__content:before {
  background-color: #063a7a;
}

.course-one__book-link {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 20;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: var(--thm-primary);
  color: var(--thm-black);
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: perspective(400px) rotateX(-90deg);
  transform: perspective(400px) rotateX(-90deg);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.course-one__single:hover .course-one__book-link {
  -webkit-transform: perspective(400px) rotateX(0);
  transform: perspective(400px) rotateX(0);
}

.course-one__book-link:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.course-one .post-pagination {
  margin-top: 30px;
}

.course-one__title {
  background-color: #edf2f5;
  position: relative;
  padding-top: 260px;
  padding-bottom: 375px;
}

.course-one__title__home-two {
  padding-top: 120px;
}

.course-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.4;
}

.course-one__title .container {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.course-one__title .block-title {
  margin-bottom: 0;
}

.course-one__carousel-wrapper {
  padding-top: 0;
  padding-bottom: 120px;
  margin-top: -333px;
  position: relative;
  z-index: 10;
}

.course-one__carousel-wrapper .container {
  position: relative;
}

.course-one__carousel .owl-stage-outer {
  left: -10px;
}

.course-one__carousel .owl-stage {
  left: 10px;
}

[class*="course-one__carousel-btn-"] {
  width: 79px;
  height: 79px;
  background-color: #fff;
  font-size: 24px;
  color: var(--thm-black);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: absolute;
  top: 40.5%;
  z-index: 11;
}

[class*="course-one__carousel-btn-"]:hover {
  background-color: var(--thm-primary);
  color: var(--thm-black);
}

.course-one__carousel-btn-left {
  right: calc(100% - 15px);
}

.course-one__carousel-btn-right {
  left: calc(100% - 25px);
}

.course-brand__wrapper {
  position: relative;
}

.course-brand__wrapper .site-footer__fish-1 {
  bottom: 25%;
  left: 3%;
}

.course-brand__wrapper .site-footer__tree-1 {
  left: 2%;
}

/*-------------------------------------------------------------- 
 ## Course Details
 --------------------------------------------------------------*/

.course-details {
  padding-top: 120px;
  padding-bottom: 120px;
}

.course-details__content {
  padding-top: 45px;
}

.course-details__content h3 {
  margin: 0;
  font-family: var(--thm-font);
  text-transform: uppercase;
  color: var(--thm-black);
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 40px;
}

.course-details__content h4 {
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  color: #063a7a;
  font-family: var(--thm-font);
  text-transform: uppercase;
  margin-top: 60px;
  margin-bottom: 30px;
}

.course-details__content p {
  margin: 0;
}

.course-details__content p + p {
  margin-top: 40px;
}

.course-details__btn {
  margin-top: 55px;
}

.course-details__image {
  position: relative;
}

.course-details__image > img {
  width: 100%;
}

.course-details__infos {
  position: absolute;
  top: calc(100% - 73.5px);
  right: 0;
  width: 100%;
  max-width: 590px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: var(--thm-primary);
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 48.5px;
  padding-bottom: 48.5px;
}

.course-details__infos::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 10px;
  height: 35px;
  background-color: var(--thm-black);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  z-index: 11;
}

.course-details__infos-title {
  text-transform: uppercase;
  width: 147px;
  height: 96px;
  background-color: var(--thm-black);
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.4em;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: rotate(-90deg) translateX(calc(-100% + 96px));
  transform: rotate(-90deg) translateX(calc(-100% + 96px));
}

.course-details__infos-single {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  flex: 1 1 33.333%;
  max-width: 33.333%;
  position: relative;
}

.course-details__infos-single + .course-details__infos-single::before {
  content: "";
  width: 9px;
  height: 57px;
  background-image: url(../images/shapes/line-2.png);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  right: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.course-details__infos-single span {
  font-size: 50px;
  font-weight: 300;
  line-height: 1em;
  color: var(--thm-black);
}

.course-details__infos-single p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1em;
  text-transform: uppercase;
  color: var(--thm-black);
  opacity: 0.7;
  margin: 0;
  margin-left: 10px;
}

/*-------------------------------------------------------------- 
 # About 
 --------------------------------------------------------------*/

.about-one {
  padding-top: 240px;
  padding-bottom: 120px;
  background-color: #edf2f5;
  position: relative;
}

.about-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.4;
}

.about-one .container {
  position: relative;
}

@media (min-width: 992px) {
  .about-one__content {
    padding-right: 55px;
  }
}

.about-one__content > p {
  margin: 0;
}

.about-one__content > p + p {
  margin-top: 60px;
}

.about-one__content p span {
  color: var(--thm-base);
  font-weight: 500;
  font-size: 22px;
}

.about-one__image {
  position: relative;
}

.about-one__image img:nth-child(1) {
  -webkit-clip-path: polygon(2% 0, 100% 0, 100% 100%, 47% 100%);
  clip-path: polygon(2% 0, 100% 0, 100% 100%, 47% 100%);
}

.about-one__image img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon(0 0, 0% 100%, 76% 100%);
  clip-path: polygon(0 0, 0% 100%, 76% 100%);
}

.about-two {
  padding-bottom: 30px;
}

.about-two .my-auto {
  width: 100%;
}

.about-two__content-wrapper {
  position: relative;
}

.about-two__content-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  right: 15px;
  background-color: var(--thm-gray);
  width: 10000px;
  height: 100%;
}

.about-two__content {
  position: relative;
}

.about-two__content .block-title {
  margin-bottom: 35px;
}

.about-two__content > p {
  margin: 0;
}

.about-two__list {
  margin: 0;
  margin-top: 60px;
  counter-reset: about-two-list-count;
}

.about-two__list li {
  position: relative;
  color: #838a93;
  padding-left: 60px;
  line-height: 41px;
  counter-increment: about-two-list-count;
}

.about-two__list li + li {
  margin-top: 20px;
}

.about-two__list-count {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 41px;
  height: 41px;
  background-color: var(--thm-primary);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}

.about-two__list-count::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: #838a93;
  opacity: 0.1;
  width: 100%;
  height: 100%;
}

.about-two__list-count::after {
  content: counters(about-two-list-count, ".", decimal-leading-zero);
  color: var(--thm-black);
  font-size: 16px;
  font-weight: 500;
}

.about-two__home-two {
  background-color: #063a7a;
  background-repeat: no-repeat;
  background-position: bottom left;
  position: relative;
  padding-bottom: 0;
}

.about-two__home-two .about-two__content-wrapper::before {
  display: none;
}

.about-two__home-two .about-two__content .block-title p,
.about-two__home-two .about-two__content > p {
  color: #c1cfe1;
}

.about-two__home-two .about-two__content .block-title h3 {
  color: #fff;
}

.about-two__home-two .about-two__list li {
  color: #fff;
}

.about-two__image {
  position: relative;
}

.about-two__home-two .about-two__image::before {
  content: "";
  position: absolute;
  left: -25px;
  top: 50%;
  width: 10px;
  height: 35px;
  background-color: var(--thm-primary);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  z-index: 26;
}

.about-two__image-title {
  text-transform: uppercase;
  width: 780px;
  height: 96px;
  background-color: var(--thm-primary);
  color: var(--thm-black);
  font-weight: 300;
  font-size: 30px;
  letter-spacing: 0.4em;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 81px;
  z-index: 25;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: rotate(-90deg) translateX(calc(-100% + 96px));
  transform: rotate(-90deg) translateX(calc(-100% + 96px));
}

.about-three {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}

.about-three__fish-1 {
  position: absolute;
  bottom: 8%;
  left: 11%;
  -webkit-animation: footerFish 5s linear infinite;
  animation: footerFish 5s linear infinite;
}

.about-three__fish-2 {
  position: absolute;
  bottom: 15%;
  right: 13%;
  -webkit-animation: footerFish 5s linear infinite 1s;
  animation: footerFish 5s linear infinite 1s;
}

.about-three__tree-1 {
  position: absolute;
  left: 3%;
  bottom: 0;
  -webkit-animation: footerTree 5s ease-in infinite;
  animation: footerTree 5s ease-in infinite;
}

.about-three__tree-2 {
  position: absolute;
  right: 3%;
  bottom: 0;
  -webkit-animation: footerTree 5s ease-in infinite;
  animation: footerTree 5s ease-in infinite;
}

.about-three .container {
  position: relative;
}

.about-three__moc {
  position: absolute;
  top: -45px;
  left: -78px;
  z-index: 12;
  -webkit-animation: ribbonRotate 10s cubic-bezier(0.19, 1, 1, 3.58) infinite;
  animation: ribbonRotate 10s cubic-bezier(0.19, 1, 1, 3.58) infinite;
}

.about-three__content .block-title {
  margin-bottom: 35px;
}
.about-three__content > p {
  margin: 0px;
}

.about-three__content > p a {
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.about-three__content > p a:hover {
  color: var(--thm-black);
}

.about-three__list {
  margin: 0;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 15px;
}
.about-three__list li {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 50%;
  max-width: 50%;
  font-size: 20px;
  color: var(--thm-black);
  padding-left: 15px;
  line-height: 1em;
  margin-bottom: 20px;
}
.about-three__list li i {
  position: absolute;
  left: 0;
  top: 60%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1em;
}

.about-three__btn {
  margin-top: 50px;
}

.about-three__image {
  position: relative;
  display: inline-block;
}

.about-three__image::before {
  content: "";
  width: 100%;
  height: 100px;
  position: absolute;
  top: 91%;
  left: 0;
  -webkit-clip-path: polygon(0 0, 100% 70%, 100% 100%, 0 30%);
  clip-path: polygon(0 0, 100% 70%, 100% 100%, 0 30%);
  background-color: var(--thm-primary);
}

.about-three__image-content {
  position: absolute;
  right: -40px;
  bottom: 90px;
  width: 100%;
  max-width: 225px;
  height: 120px;
  background-color: var(--thm-base);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.about-three__image > img {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 89%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 89%);
}
.about-three__image-content::before {
  content: "";
  width: 40px;
  height: 30px;
  background-color: #2d7ec8;
  position: absolute;
  top: 100%;
  right: 0;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}
.about-three__image-content p,
.about-three__image-content h3 {
  margin: 0;
}
.about-three__image-content h3 {
  font-family: var(--thm-font);
  color: #fff;
  font-size: 50px;
  font-weight: 300;
}
.about-three__image-content p {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1em;
  opacity: 0.7;
  font-weight: 500;
  margin-left: 20px;
}

.about-three__floated-text {
  position: absolute;
  top: 0;
  left: -55px;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  font-size: 110px;
  font-family: var(--thm-font);
  letter-spacing: 0.6em;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #edf2f5;
  text-transform: uppercase;
  font-weight: 800;
}

/*-------------------------------------------------------------- 
 # Pricing 
 --------------------------------------------------------------*/

.pricing-one {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.pricing-one .container {
  position: relative;
}

.pricing-one .table-responsive {
  border: 1px solid #e3e9ec;
  border-top: 0;
  background-color: #fff;
}

.pricing-one table {
  width: 100%;
  padding-left: 1px;
}

.pricing-one table thead {
  background-color: var(--thm-base);
}

.pricing-one table thead th {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  padding-top: 25px;
  padding-bottom: 25px;
}

.pricing-one table thead th:nth-child(1) {
  padding-left: 70px;
}

.pricing-one table tbody tr + tr {
  border-top: 1px solid #e3e9ec;
}

.pricing-one table tbody td {
  font-size: 20px;
  color: #838a93;
  padding-top: 25px;
  padding-bottom: 25px;
}

.pricing-one table tbody td:nth-child(1) {
  padding-left: 70px;
}

/*-------------------------------------------------------------- 
 # Brand 
 --------------------------------------------------------------*/

.brand-one__carousel {
  padding-top: 93px;
  padding-bottom: 93px;
  border-top: 1px solid #e3e9ec;
  position: relative;
  z-index: 11;
}

.brand-one__home-one {
  background-color: var(--thm-base);
  position: relative;
  padding-top: 25px;
}

.brand-one__home-one::before {
  content: "";
  width: 100%;
  height: 32px;
  display: block;
  background-image: url(../images/shapes/wave-3.png);
  background-position: center center;
  position: absolute;
  top: -7px;
  left: 0;
  -webkit-animation: bgSlide 20s linear infinite;
  animation: bgSlide 20s linear infinite;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.brand-one__home-one .brand-one__carousel {
  border-top: 0;
}

/*-------------------------------------------------------------- 
 # Team 
 --------------------------------------------------------------*/

.team-one {
  padding-top: 120px;
  padding-bottom: 90px;
}

.team-one__single {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  background-color: var(--thm-black);
}

.team-one__single > img {
  width: 100%;
  opacity: 1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
  z-index: 9;
}

.team-one__single:hover > img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.team-one__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: transparent;
  text-align: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-transition: all 500ms ease 0s;
  transition: all 500ms ease 0s;
}

.team-one__content::before,
.team-one__content::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  top: 0;
  left: 0;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}

.team-one__content::before {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  border-top: 8px solid var(--thm-primary);
  border-bottom: 8px solid var(--thm-primary);
}

.team-one__content::after {
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  border-left: 8px solid var(--thm-primary);
  border-right: 8px solid var(--thm-primary);
}

.team-one__single .team-one__content:hover::before,
.team-one__single .team-one__content:hover::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.team-one__single:hover .team-one__content {
  background-color: rgba(var(--thm-black-rgb), 0.8);
}

.team-one__content-inner {
  position: relative;
  z-index: 20;
  opacity: 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: perspective(500px) rotate(45deg);
  transform: perspective(500px) rotate(15deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: opacity 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: opacity 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  transition: transform 500ms ease 0ms, opacity 500ms ease 0ms;
  transition: transform 500ms ease 0ms, opacity 500ms ease 0ms,
    -webkit-transform 500ms ease 0ms;
}

.team-one__single:hover .team-one__content-inner {
  opacity: 1;
  -webkit-transform: perspective(500px) rotate(0deg);
  transform: perspective(500px) rotate(0deg);
  -webkit-transition: opacity 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
  transition: opacity 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
  transition: transform 500ms ease 500ms, opacity 500ms ease 500ms;
  transition: transform 500ms ease 500ms, opacity 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
}

.team-one__content h3 {
  margin: 0;
  color: #fff;
  font-family: var(--thm-font);
  font-weight: 500;
  font-size: 22px;
  line-height: 1em;
  text-transform: uppercase;
}

.team-one__content span {
  margin: 0;
  line-height: 1em;
  color: #c1cfe1;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-top: 5px;
}

.team-one__social {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 30px;
}

.team-one__social a {
  width: 41px;
  height: 41px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: var(--thm-black);
  background-color: var(--thm-primary);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-one__social a:hover {
  color: #fff;
  background-color: var(--thm-black);
}

.team-one__social a + a {
  margin-left: 10px;
}

.team-brand__wrapper {
  background-position: bottom center;
  background-repeat: no-repeat;
}

.team-one__home-two {
  padding-top: 0;
  padding-bottom: 0;
}

.team-one__home-two__wrap {
  position: relative;
  padding-top: 120px;
  padding-bottom: 90px;
}

.team-one__home-two__wrap::before {
  content: "";
  position: absolute;
  top: 0;
  left: 120px;
  background-color: var(--thm-gray);
  width: 10000px;
  height: 100%;
}

.team-one__home-two__wrap .row {
  position: relative;
}

.team-one__block-content {
  padding-top: 120px;
  padding-bottom: 120px;
}

.team-one__block-content .block-title {
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .team-one__block-content {
    padding-right: 70px;
  }
}

.team-one__block-content > p {
  margin: 0;
}

/*-------------------------------------------------------------- 
 ## Team Progress
 --------------------------------------------------------------*/

.team-one__progress-wrap {
  margin-top: 45px;
}

.team-one__progress__bar + .team-one__progress__bar {
  margin-top: 33px;
}

.team-one__progress__bar-top {
  margin-bottom: 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.team-one__progress__bar-top h3 {
  font-family: var(--thm-font);
  font-size: 18px;
  font-weight: 500;
  color: var(--thm-black);
  margin: 0;
  text-transform: uppercase;
  line-height: 1em;
}

.team-one__progress__bar-top p {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  color: #838a93;
  line-height: 1em;
}

.team-one__progress__bar-line {
  width: 100%;
  height: 3px;
  background-color: #eceeef;
  position: relative;
  overflow: hidden;
}

.team-one__progress__bar-line span {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--thm-base);
}

.team-one {
  position: relative;
}

.team-one__floated-text {
  position: absolute;
  top: 0;
  left: 10%;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  font-size: 110px;
  font-family: var(--thm-font);
  letter-spacing: 0.6em;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #edf2f5;
  text-transform: uppercase;
  font-weight: 800;
}

/*-------------------------------------------------------------- 
 # Testimonials 
 --------------------------------------------------------------*/

.testimonials-one__title {
  background-color: #edf2f5;
  position: relative;
  padding-top: 260px;
  padding-bottom: 295px;
}

.testimonials-one__title__home-one {
  padding-top: 180px;
}

.testimonials-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.4;
}

.testimonials-one__title .container {
  position: relative;
}

.testimonials-one__title .block-title {
  margin-bottom: 0;
}

.testimonials-one__carousel-wrapper {
  position: relative;
  z-index: 11;
  margin-top: -250px;
}

.testimonials-one__carousel .owl-stage-outer {
  overflow: unset;
}

.testimonials-one__carousel .owl-item img {
  width: auto;
}

.testimonials-one__single {
  border-style: solid;
  border-width: 1px;
  border-color: #e3e9ec;
  background-color: rgb(255, 255, 255);
  position: relative;
}

.testimonials-one__single::before,
.testimonials-one__single::after {
  content: "";
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  top: -1px;
  left: -1px;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}

.testimonials-one__single::before {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  border-top: 8px solid var(--thm-primary);
  border-bottom: 8px solid var(--thm-primary);
}

.testimonials-one__single::after {
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  border-left: 8px solid var(--thm-primary);
  border-right: 8px solid var(--thm-primary);
}

.testimonials-one__single:hover::before,
.testimonials-one__single:hover::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.testimonials-one__content {
  position: relative;
  padding-top: 50px;
  padding-left: 60px;
  padding-right: 60px;
}

.testimonials-one__qoute {
  width: 63px;
  height: 63px;
  background-color: #e3e9ec;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 10;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonials-one__single:hover .testimonials-one__qoute {
  background-color: var(--thm-primary);
}

.testimonials-one__qoute::after,
.testimonials-one__qoute::before {
  content: "";
  background-image: url(../images/shapes/qoute-1-1.png);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonials-one__qoute::after {
  opacity: 0;
  background-image: url(../images/shapes/qoute-1-2.png);
}

.testimonials-one__single:hover .testimonials-one__qoute::after {
  opacity: 1;
}

.testimonials-one__single:hover .testimonials-one__qoute::before {
  opacity: 0;
}

.testimonials-one__content-inner p {
  margin: 0;
  font-size: 22px;
  line-height: 34px;
  font-weight: 300;
  color: #838a93;
  margin-bottom: 30px;
}

.testimonials-one__infos {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
  border-top: 1px solid #e3e9ec;
}

.testimonials-one__infos-content {
  margin-left: 20px;
}

.testimonials-one__infos-content h3 {
  font-family: var(--thm-font);
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--thm-base);
  line-height: 1em;
}

.testimonials-one__infos-content span {
  color: #838a93;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1em;
  margin-top: 3px;
  display: block;
}

.testimonials-one__carousel .owl-nav.disabled + .owl-dots,
.testimonials-one__carousel .owl-dots {
  margin-top: 35px;
}

.testimonials-one__carousel .owl-dots .owl-dot {
  margin: 0;
  outline: none;
}

.testimonials-one__carousel .owl-dots .owl-dot + .owl-dot {
  margin-left: 10px;
}

.testimonials-one__carousel .owl-dots .owl-dot span {
  width: 19px;
  height: 19px;
  background-color: transparent !important;
  border: 4px solid #051b35;
  border-radius: 0;
  opacity: 0.2;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin: 0;
  position: relative;
}

.testimonials-one__carousel .owl-dots .owl-dot:hover span,
.testimonials-one__carousel .owl-dots .owl-dot.active span {
  opacity: 1;
}

.testimonials-one__carousel .owl-nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 50px;
}

.testimonials-one__carousel .owl-nav button.owl-next,
.testimonials-one__carousel .owl-nav button.owl-prev {
  width: 45px;
  height: 45px;
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: var(--thm-gray);
  margin: 0 10px;
  font-size: 24px;
  border-radius: 0px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonials-one__carousel .owl-nav button.owl-next span,
.testimonials-one__carousel .owl-nav button.owl-prev span {
  line-height: 1em;
  font-size: 24px;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonials-one__carousel .owl-nav button.owl-next:hover,
.testimonials-one__carousel .owl-nav button.owl-prev:hover {
  background-color: var(--thm-primary);
}

.testimonials-one__carousel .owl-nav button.owl-next:hover span,
.testimonials-one__carousel .owl-nav button.owl-prev:hover span {
  color: var(--thm-black);
}

.testimonials-one__title__home-two {
  padding-top: 120px;
}

.testimonials-one__carousel-wrapper__home-one {
  padding-bottom: 150px;
}

.testimonials-one__carousel-wrapper__home-two {
  padding-bottom: 145px;
}

/*-------------------------------------------------------------- 
 # Funfact 
 --------------------------------------------------------------*/

.funfact-one .container {
  position: relative;
}

.funfact-one__title {
  text-transform: uppercase;
  width: 283px;
  height: 96px;
  background-color: var(--thm-black);
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.4em;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 25;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: rotate(-90deg) translateX(calc(-100% + 96px));
  transform: rotate(-90deg) translateX(calc(-100% + 96px));
}

@media (max-width: 1280px) {
  .funfact-one__title {
    left: 55px;
  }
}

.funfact-one .inner-container {
  background-color: var(--thm-primary);
  padding-left: 80px;
  padding-top: 111.5px;
  padding-bottom: 111.5px;
  position: relative;
  z-index: 22;
  margin-bottom: -141.5px;
}

.funfact-one__home-two .inner-container {
  margin-top: 0;
}

.funfact-one__home-one {
  margin-top: 0;
}

.funfact-one .inner-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 10px;
  height: 35px;
  background-color: var(--thm-black);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  z-index: 11;
}

@media (max-width: 1280px) {
  .funfact-one .inner-container::before {
    left: 40px;
  }
}

.funfact-one .inner-container::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 10000px;
  background-color: var(--thm-primary);
}

.funfact-one__single {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.funfact-one__single::before {
  content: "";
  width: 9px;
  height: 57px;
  background-image: url(../images/shapes/line-2.png);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

[class*="col-"]:last-child .funfact-one__single::before {
  display: none;
}

[class*="col-"] + [class*="col-"] .funfact-one__single {
  padding-left: 30px;
}

.funfact-one__count {
  font-size: 60px;
  font-weight: 300;
  color: #051b35;
  line-height: 1em;
  width: 96px;
}

.funfact-one__content {
  margin-left: 20px;
}

.funfact-one__content h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: rgba(5, 27, 53, 0.7);
  text-transform: uppercase;
  font-family: var(--thm-font);
}

/*-------------------------------------------------------------- 
 # Video 
 --------------------------------------------------------------*/

.video-one {
  background-color: #063a7a;
  position: relative;
  padding-top: 120px;
  padding-bottom: 250px;
  margin-bottom: -141.5px;
}

.video-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.2;
}

.video-one .container {
  position: relative;
}

.video-one .video-popup {
  width: 121px;
  height: 121px;
  background-color: var(--thm-primary);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  color: var(--thm-black);
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.video-one .video-popup::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.1;
  position: absolute;
  top: -10px;
  left: -10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.video-one .video-popup:hover::before {
  top: 0;
  left: 0;
}

.video-one h3 {
  text-transform: uppercase;
  margin: 0;
  font-family: var(--thm-font);
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  margin-top: 40px;
}

.video-one h3 span {
  color: var(--thm-primary);
}

.video-one__home-two {
  margin-bottom: 0;
  padding-bottom: 120px;
  padding-top: 250px;
  margin-top: -141.5px;
}

.video-two {
  background-color: #063a7a;
  background-repeat: no-repeat;
  background-position: bottom left;
  position: relative;
}

.video-two__swimmer {
  position: absolute;
  top: 10%;
  left: 40%;
  z-index: 10;
  -webkit-animation: contactSwimmer 7s linear infinite;
  animation: contactSwimmer 7s linear infinite;
}

.video-two .container {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}

.video-two__box {
  position: absolute;
  right: 15px;
  bottom: -60px;
  z-index: 15;
}

@media (min-width: 992px) {
  .video-two__content {
    padding-right: 70px;
  }
}

.video-two__content .block-title {
  margin-bottom: 30px;
}

.video-two__content .block-title p {
  color: #c1cfe1;
}

.video-two__content .block-title h3 {
  color: #fff;
}

.video-two__content > p {
  color: #c1cfe1;
  margin: 0;
}

.video-two__btn {
  margin-top: 50px;
}

.video-two .video-popup {
  width: 121px;
  height: 121px;
  background-color: var(--thm-primary);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  left: -60.5px;
  bottom: 60px;
  font-size: 20px;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.video-two .video-popup::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.1;
  position: absolute;
  top: -10px;
  left: -10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.video-two .video-popup:hover::before {
  top: 0;
  left: 0;
}

/*-------------------------------------------------------------- 
 # Features 
 --------------------------------------------------------------*/

.feature-one {
  position: relative;
  z-index: 20;
  margin-top: -40px;
}

.feature-one__single {
  background-color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);
}

.feature-one__single:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.feature-one__single::before,
.feature-one__single::after {
  content: "";
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  top: -8px;
  left: -8px;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}

.feature-one__single::before {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  border-top: 8px solid var(--thm-primary);
  border-bottom: 8px solid var(--thm-primary);
}

.feature-one__single::after {
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  border-left: 8px solid var(--thm-primary);
  border-right: 8px solid var(--thm-primary);
}

.feature-one__single:hover::before,
.feature-one__single:hover::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.feature-one__image-inner {
  position: relative;
  width: 150px;
  background-color: var(--thm-black);
}

.feature-one__image-inner > img {
  width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-one__single:hover .feature-one__image-inner > img {
  opacity: 0.5;
}

.feature-one__content {
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  z-index: 11;
}

.feature-one__content h3 {
  color: var(--thm-black);
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  font-family: var(--thm-font);
  margin: 0;
}

.feature-one__content h3 a {
  color: inherit;
}

.feature-two {
  padding-top: 120px;
  padding-bottom: 90px;
}

.feature-two__single {
  margin-bottom: 30px;
}

.feature-two__image {
  overflow: hidden;
  background-color: var(--thm-black);
}

.feature-two__image > img {
  width: 100%;
  opacity: 1;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-two__single:hover .feature-two__image > img {
  opacity: 0.5;
  -webkit-transform: scale(1.07);
  transform: scale(1.07);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.feature-two__content {
  position: relative;
  border-left: 8px solid var(--thm-primary);
  padding-top: 50px;
  padding-bottom: 55px;
  padding-left: 60px;
  padding-right: 50px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-two__single:hover .feature-two__content {
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03),
    inset 7.989px -0.419px 0px 0px rgba(255, 215, 1, 0.004);
}

.feature-two__content h3 {
  margin: 0;
  font-family: var(--thm-font);
  font-weight: bold;
  color: #063a7a;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.feature-two__content h3 a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-two__content h3 a:hover {
  color: var(--thm-primary);
}

.feature-two__content p {
  margin: 0;
  color: #838a93;
}

/*-------------------------------------------------------------- 
 # Services 
 --------------------------------------------------------------*/

.service-one {
  padding-top: 120px;
  padding-bottom: 90px;
  position: relative;
}

.service-one__floated-text {
  font-size: 110px;
  font-weight: bold;
  color: #edf2f5;
  position: absolute;
  bottom: -12px;
  left: 50%;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.6em;
  line-height: 1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.service-one__single {
  margin-bottom: 30px;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
  padding-top: 60px;
}

.service-one__single::before,
.service-one__single::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  top: 0;
  left: 0;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}

.service-one__single::before {
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  border-top: 8px solid var(--thm-primary);
  border-bottom: 8px solid var(--thm-primary);
}

.service-one__single::after {
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  border-left: 8px solid var(--thm-primary);
  border-right: 8px solid var(--thm-primary);
}

.service-one__single:hover::before,
.service-one__single:hover::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.service-one__single i {
  position: relative;
  font-size: 64px;
  color: var(--thm-base);
  display: inline-block;
}

.service-one__single i::after {
  content: "";
  width: 61px;
  height: 61px;
  border: 8px solid var(--thm-primary);
  opacity: 0.2;
  position: absolute;
  left: -32px;
  top: -20px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-one__single:hover i::after {
  border-color: var(--thm-base);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.service-one__single h3 {
  margin: 0;
  font-family: var(--thm-font);
  text-transform: uppercase;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  color: #063a7a;
  margin-top: 15px;
  margin-bottom: 25px;
}

.service-one__single h3 a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-one__single h3 a:hover {
  color: var(--thm-primary);
}

.service-one__single p {
  margin: 0;
  width: 69%;
  margin-left: auto;
  margin-right: auto;
}

/*-------------------------------------------------------------- 
 # FAQ 
 --------------------------------------------------------------*/

.faq-one-accrodion .accrodion {
  border-style: solid;
  border-width: 1px;
  border-color: #e3e9ec;
  background-color: rgb(255, 255, 255);
  padding-left: 30px;
  padding-right: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion + .accrodion {
  margin-top: 22px;
}

.faq-one-accrodion .accrodion.active {
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);
}

.faq-one-accrodion .accrodion-title {
  cursor: pointer;
}

.faq-one-accrodion .accrodion-title h4 {
  margin: 0;
  font-family: var(--thm-font);
  color: var(--thm-black);
  font-size: 20px;
  font-weight: 600;
  position: relative;
  padding-top: 28px;
  padding-bottom: 28px;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion.active .accrodion-title h4 {
  color: var(--thm-primary);
}

.faq-one-accrodion .accrodion-title h4::before {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: 30px;
  height: 30px;
  background-color: var(--thm-primary);
  color: var(--thm-black);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion.active .accrodion-title h4::before {
  content: "\f106";
  color: #fff;
  background-color: var(--thm-base);
}

.faq-one-accrodion .accrodion-content {
  padding-bottom: 30px;
  padding-top: 20px;
  margin-left: -30px;
  margin-right: -20px;
  padding-left: 30px;
  padding-right: 30px;
  border-top: 1px solid #e3e9ec;
}

.faq-one-accrodion .accrodion-content p {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  color: #828690;
}

.mm-hidden {
  visibility: hidden;
}
.m-0 {
  padding-left: 20px;
  padding-right: 20px;
}
.socialhidetext {
  line-height: 0;
  font-size: 0;
  color: transparent;
}
.mm-trans {
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
  font-size: x-small;
}
